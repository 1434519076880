<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="[8, 16]">
				<a-col :span="4">
					<a-select
						allowClear
						style="width: 100%"
						placeholder="异常类型"
						v-model="abnormalTypeCode"
						@change="getData()"
					>
						<a-select-option v-for="item in abnormalTypeList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
			>
				<p class="subscriptionEndUtc" slot="subscriptionEndUtc" slot-scope="record">{{ record }}</p>
				<a class="isActive" slot="isActive" slot-scope="record">
					<a-icon v-if="record" type="check" />
					<a-icon v-if="!record" type="close" />
				</a>
			</a-table>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import { EmpattdailyServiceProxy } from '../../../shared/service-proxies';
import Dic from '../../../shared/utils/dic';
import moment from 'moment';

export default {
	name: 'daily-myerror',
	mixins: [AppComponentBase],
	data() {
		return {
			abnormalTypeList: [], //异常类型
			columns: [
				{
					title: '异常日期',
					width: 150,
					sorter: true,
					errorDate: true,
					dataIndex: 'errorDate',
					fixed: 'left',
					align: 'center',
					scopedSlots: { customRender: 'errorDate' },
				},
				{
					title: '班次',
					dataIndex: 'shiftName',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'shiftName' },
				},
				{
					title: '上班时间',
					width: 200,
					ellipsis: true,
					sorter: true,
					dataIndex: 'dutyFrom',
					align: 'center',
					scopedSlots: { customRender: 'dutyFrom' },
				},
				{
					title: '下班时间',
					width: 200,
					ellipsis: true,
					sorter: true,
					dataIndex: 'dutyTo',
					align: 'center',
					scopedSlots: { customRender: 'dutyTo' },
				},
				{ title: '异常类型', sorter: true, dataIndex: 'abnormalType', ellipsis: true, align: 'center' },
			],
			tableData: [],
			abnormalTypeCode: undefined,
		};
	},
	created() {
		this._empattdailyServiceProxy = new EmpattdailyServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.abnormalTypeList = await Dic.getInstance().getDicAsync('QYB_AbnormalType');
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this._empattdailyServiceProxy
				.getMyAttErrorByNowMon(this.abnormalTypeCode)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res;
					this.tableData.map((item) => {
						item.errorDate = item.errorDate ? moment(item.errorDate).format('YYYY-MM-DD') : '';
						item.dutyFrom = item.dutyFrom ? moment(item.dutyFrom).format('YYYY-MM-DD hh:mm') : '';
						item.dutyTo = item.dutyTo ? moment(item.dutyTo).format('YYYY-MM-DD hh:mm') : '';
					});
				});
		},
	},
};
</script>

<style>
.red-color {
	color: red;
}

.pagination {
	margin: 10px auto;
	text-align: right;
}

.edit-cell {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cell-text {
	flex: 4;
	max-width: 100px;
	min-width: 100px;
}

.cell-iocn {
	padding-left: 2px;
	flex: 1;
	max-width: 20px;
	min-width: 20px;
	cursor: pointer;
}

.editable-cell-icon {
	display: none;
}

.edit-cell:hover .editable-cell-icon {
	color: #108ee9;
	display: inline-block;
}
</style>
